import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, NgZone, OnDestroy } from '@angular/core';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { DisplayListOperationsService } from 'src/app/displays/services/display-list-operations.service';
import { DisplayService } from '../../services/display.service';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { PageWithListComponent, ScrollingList } from 'src/app/components/scrolling-list/page-with-list.component';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

@Component({
  selector: 'display-list',
  templateUrl: './display-list.component.html',
  styleUrls: ['./display-list.component.scss']
})
export class DisplayListComponent
  extends PageWithListComponent<any>
  implements AfterViewInit, OnDestroy {
  search = {
    sortBy: 'name',
    reverse: false,
    name: 'Displays',
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    },
    includeSubcompanies: false,
    query: undefined,
    selectAll: false,
  };

  listOperations = this.displayListOperationsService.listOperations;
  displays: ScrollingList<Display> = this.ScrollingListService(this.displayApiService.list.bind(this.displayApiService), this.search, this.listOperations);

  allCompanies;

  selectedCompayId = this.companyStateService.getSelectedCompanyId();
  scheduleOverrides: string[] = [];

  filterConfig = {
    placeholder: 'Search Displays'
  };

  private subscription: any;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private broadcaster: BroadcasterService,
    private ScrollingListService: ScrollingListService,
    public userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private displayApiService: DisplayApiService,
    public displayService: DisplayService,
    private displayListOperationsService: DisplayListOperationsService,
    private ngZone: NgZone)
  {
    super();
    this.subscription = this.broadcaster.subscribe({
      next: (event: String) => {
        switch (event) {
        case 'risevision.company.planStarted':
          // use doSearch because it clears the list
          this.displays.doSearch();
          break;
        default:
          return;
        }
      }
    });

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleAllCompanies(value) {
    this.allCompanies = value;

    this.search.includeSubcompanies = value;
    this.displays.doSearch();
  }

  getLicenseStatus(display) {
    if (display && display.playerProAuthorized) {
      return 'Yes';
    } else if (display && !display.playerProAuthorized && display.isFreeForURLs) {
      return 'Free';
    } else {
      return '−';
    }
  }

  refresh () {
    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }

  showPopover (overrideNames: string[]) {
    this.scheduleOverrides = overrideNames;
    this.refresh();
  }

  getScrollingList(): ScrollingList<any> {
    return this.displays;
  }

  protected isToday(date: string): boolean {
    const today = new Date();
    const dateToCheck = new Date(date);

    return dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear();
  }

}

interface Display {
  id: string;
  name: string;
  companyId: string;
  companyName: string;
  playerProAuthorized: boolean;
  isFreeForURLs: boolean;
  scheduleCount: number;
  revisionStatusName: string;
  scheduleOverrides: string[];
}
