import { Component, DestroyRef, inject, NgZone, AfterViewInit } from '@angular/core';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyIdModalComponent } from 'src/app/components/scrolling-list/company-id-modal/company-id-modal.component';
import { PageWithListComponent, ScrollingList } from 'src/app/components/scrolling-list/page-with-list.component';
import { UsernamePipe } from 'src/app/shared/pipes/username.pipe';
import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';
import { EditorService } from '../../services/editor.service';
import { PresentationUtilsService } from '../../services/presentation-utils.service';

@Component({
  selector: 'presentation-list',
  templateUrl: './presentation-list.component.html',
  styleUrls: ['./presentation-list.component.scss']
})
export class PresentationListComponent extends PageWithListComponent<Presentation> implements AfterViewInit {

  search = {
    sortBy: 'changeDate',
    reverse: true,
    name: 'Presentations',
    selectAll: false,
    query: undefined
  };

  listOperations = {
    name: 'Presentation',
    operations: [{
      name: 'Copy',
      beforeBatchAction: (selected) => {
        const plural = selected.length > 1 ? 's' : '';
        return this.modalService.showDialog(CompanyIdModalComponent, {
          title: 'Copy Selected Presentation' + plural,
          description: 'A copy of ' + (plural ? 'each of ' : '') + 'the selected presentation' + plural +
            ' will be added to the Company ID specified below.'
        });
      },
      actionCall: (presentation?, companyId?) => {
        return this.editorService.copyPresentationToCompany(presentation, companyId).then(() => {
          if (presentation.companyId === companyId) {
            this.presentations.doSearch();
          }
        });
      },
      requireRole: 'sa',
      mainOperation: true
    },
    {
      name: 'Export',
      beforeBatchAction: (selected) => {
        return this.modalService.confirm('Export presentations?',
          'An export file will be prepared and emailed to you at <b>' + this.userStateService.getUserEmail() +
          '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
          'Export', 'Cancel');
      },
      actionCall: this.presentationApiService.export.bind(this.presentationApiService),
      groupBy: true,
      requireRole: 'sa',
      mainOperation: true
    },
    {
      name: 'Delete',
      actionCall: (presentation?) => {
        return this.editorService.deletePresentationByObject(presentation, true);
      },
      showActionError: (err?) => {
        if (err && err.status === 409) {
          return true;
        }
        return false;
      },
      requireRole: 'cp ap',
      mainOperation: true
    }]
  };
  presentations: ScrollingList<Presentation> = this.ScrollingListService(this.presentationApiService.list.bind(this.presentationApiService), this.search, this.listOperations);

  isHtmlPresentation = this.presentationUtils.isHtmlPresentation;

  filterConfig = {
    placeholder: 'Search Presentations',
    id: 'presentationSearchInput'
  };

  assignedToUsers = '';

  constructor(private ScrollingListService: ScrollingListService,
    private userStateService: UserStateService,
    private presentationApiService: PresentationApiService,
    public editorService: EditorService,
    public templateEditorFactory: TemplateEditorService,
    private presentationUtils: PresentationUtilsService,
    private modalService: ModalService,
    public companyStateService: CompanyStateService,
    private usernamePipe: UsernamePipe,
    private ngZone: NgZone) {
      super();
    }

    ngAfterViewInit(): void {
      super.ngAfterViewInit();
    }

    popoverText(assignedUsers: string[]) {
      if (assignedUsers.length > 1) {
        this.assignedToUsers = assignedUsers
          .slice(1)
          .map(user => user ? this.usernamePipe.transform(user) : '')
          .join('<br>');
      } else {
        this.assignedToUsers = '';
      }

      this.refresh();
    }

    refresh () {
      setTimeout(() => {
        this.ngZone.run(() => {});
      });
    }

    getScrollingList(): ScrollingList<Presentation> {
      return this.presentations;
    }
}


export interface Presentation {
  id: string;
  name: string;
  scheduleCount: number;
  revisionStatusName: string;
  changeDate: Date;
  changedBy: string;
  selected: boolean;
  assignedUsers: string[];
  scheduleIds: string[];
}
