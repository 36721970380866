<ng-template
  #menuLink
  let-id="id"
  let-route="route"
  let-icon="icon"
  let-label="label"
  let-states="states"
>
  <a
    [id]="id"
    class="menu-item"
    [uiSref]="route"
    [ngClass]="{'selected': states && states.indexOf(currentRoute) > -1}"
    matTooltip="{{label}}"
    matTooltipPosition="right"
    matTooltipClass="menu-tooltip"
    [matTooltipDisabled]="state !== 'collapse'"
  >
    <mat-icon aria-hidden="true" [svgIcon]="icon"></mat-icon>
    <span>{{label}}</span>
  </a>
</ng-template>

<ng-template
  #menuButton
  let-id="id"
  let-icon="icon"
  let-label="label"
  let-tooltip="tooltip"
  let-addon="addon"
  let-addonClass="addonClass"
  let-states="states"
  let-clickHandler="clickHandler"
>
  <button
    [id]="id"
    class="menu-item"
    (click)="clickHandler()"
    [ngClass]="{'selected': states && states.indexOf(currentRoute) > -1}"
    matTooltip="{{tooltip}}"
    matTooltipPosition="right"
    matTooltipClass="menu-tooltip"
    [matTooltipDisabled]="state !== 'collapse'"
  >
    @if (icon) {
      <mat-icon aria-hidden="true" [svgIcon]="icon"></mat-icon>
    }
    @else {
      <company-badge [name]="label" [size]="20"></company-badge>
    }
    <div class="menu-item-button">
      <div class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-w-full tw-max-w-full tw-min-w-[0]">
        <span class="tw-truncate">{{label}}</span>
        @if (addon) {
          <mat-icon aria-hidden="true" [svgIcon]="addon" class="addon {{addonClass}}"></mat-icon>
        }
      </div>
    </div>
  </button>
</ng-template>

<div id="sidebar" [ngClass]="state">
  <a [uiSref]="rvshareAppDetectionService.isScreenShareApp() ? 'apps.screen-sharing.home' : 'apps.home'" id="logo">
    <mat-icon aria-hidden="true" svgIcon="rise-logo"></mat-icon>
    <h1>Rise Vision</h1>
  </a>

  <div class="divider tw-mb-3"></div>

  @if (companyStateService.getSelectedCompanyName()) {
    <ng-container *ngTemplateOutlet="menuButton; context: {
      id: 'company-menu-button',
      label: companyStateService.getSelectedCompanyName(),
      tooltip: 'Company',
      addon: userStateService.isAssignedPublisher() ? null : 'chevron-down',
      clickHandler: bindSelectCompany
    }"></ng-container>
  }

  @if (!rvshareAppDetectionService.isScreenShareApp()) {
    <div id="sidebar-nav">
      <ng-container *ngTemplateOutlet="menuLink; context: navOptions['presentations']"></ng-container>

      <ng-container *ngTemplateOutlet="menuLink; context: navOptions['schedules']"></ng-container>

      @if (!userStateService.isAssignedPublisher()) {
        <ng-container *ngTemplateOutlet="menuLink; context: navOptions['displays']"></ng-container>
      }

      <div class="divider tw-my-1"></div>

      @if (featuresService.isFeatureAvailable('screen_sharing')) {
        <ng-container *ngTemplateOutlet="menuLink; context: navOptions['screen-sharing']"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="menuButton; context: navOptions['screen-sharing-upgrade']"></ng-container>
      }

      @if (!userStateService.isAssignedPublisher()) {
        <ng-container *ngTemplateOutlet="menuLink; context: navOptions['storage']"></ng-container>

        <ng-container *ngTemplateOutlet="menuLink; context: navOptions['cap-alerts']"></ng-container>
      }
    </div>
  }
</div>
