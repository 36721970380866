<div  rvSpinner="update-subscription-loader" [rvShowSpinner]="updateSubscriptionService.loading || subscriptionService.loading">
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <h1 class="app-header-title">Change Subscription Type</h1>
  </div>
  <div rv-spinner rv-spinner-key="update-subscription-loader"></div>
  <div [shown]="updateSubscriptionService.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <strong>{{updateSubscriptionService.apiError}}</strong>
    <br/>If you need help please email us at support&#64;risevision.com.
  </div>
  <div id="purchase-licenses-panel" class="row">
    <div class="col-md-8 col-md-offset-2" [hidden]="updateSubscriptionService.purchases[0]?.completed">
      <form #purchaseLicensesForm="ngForm" id="forms.purchaseLicensesForm" role="form" name="forms.purchaseLicensesForm" novalidate (ngSubmit)="completePayment()">
        <h4 class="u_margin-md-bottom">Subscription Details</h4>
        @if (downgrade || billingUtils.hasPendingChange(subscriptionService.getItemSubscription())) {
          <pending-change-alert [isUpdate]="true" [upgrade]="!downgrade"></pending-change-alert>
        }
        <div class="border-container p-4">
          <div class="border-bottom py-4 mb-4" [shown]="purchaseAction === 'unlimited'">
            <p class="font-weight-bold">
              Upgrade to an Unlimited License subscription
            </p>
            <p class="left-right-aligner">
              <span class="font-weight-bold">Total number of display licenses for this subscription:</span>
              <span>Unlimited</span>
            </p>
            <p class="mb-4">
              <span class="font-weight-bold">Note:</span> An Unlimited License is a site license per school building. If purchasing for a school district or network of schools, you need to purchase a separate license for each school. If you have any questions, our <a href="https://www.risevision.com/contact-us?form_selected=sales&content_hide=true" target="_blank">Sales Team</a> is happy to help!
            </p>
          </div>
          <div class="border-bottom py-4 mb-4" [shown]="purchaseAction !== 'unlimited'">
            @if (plan) {
              <div class="font-weight-bold mb-2">
                Change to a{{ plan.name.indexOf('Basic') < 0 ? 'n' : '' }} {{ plan.name }} subscription
              </div>
            }
            <p class="left-right-aligner mb-0" [shown]="purchaseAction !== 'unlimited'">
              <span class="font-weight-bold">Total number of display licenses for this subscription:</span>
              <span>
                {{updateSubscriptionService.getTotalDisplayCount()}}
              </span>
            </p>
            <div class="madero-style alert alert-success price-update mt-3 mb-0">
              <p class="mb-0"><strong>Your new cost will be {{ updateSubscriptionService.displayLicensePurchase.newPricePerDisplay | currency }} per display license, per {{updateSubscriptionService.displayLicensePurchase.isYearly ? 'year' : 'month'}}.</strong></p>
            </div>
          </div>
          <p class="mb-0" [shown]="!addCoupon && !couponCode">
            &nbsp;
            <span class="pull-right">
              <a aria-label="Add Coupon Code" class="madero-link u_clickable" (click)="addCoupon = true" tabindex="1">Add A Coupon Code</a>
            </span>
          </p>
          <div class="row" [shown]="addCoupon">
            <div class="col-md-12">
              <div class="form-group mb-0">
                <label for="coupon-code" class="control-label">Coupon Code:</label>
                <span class="pull-right">
                  <a aria-label="Cancel Coupon Code" class="madero-link u_clickable" (click)="clearCouponCode()" tabindex="1">Cancel</a>
                </span>
                <div class="flex-row">
                  <input id="coupon-code" aria-required="false" type="text" class="form-control mr-3" name="couponCode" [(ngModel)]="couponCode" (keyup.Enter)="applyCouponCode()">
                  <button id="apply-coupon-code" type="button" aria-label="Apply Coupon Code" class="btn btn-default" (click)="applyCouponCode()">Apply</button>
                </div>
              </div>
            </div>
          </div>
          @if (updateSubscriptionService.estimates && updateSubscriptionService.estimates.length > 0) {
            <div>
              @if (updateSubscriptionService.estimates[0].invoiceEstimate) {
                <div class="border-bottom pb-4">
                  @for (coupon of updateSubscriptionService.estimates[0].invoice_estimate.discounts; track coupon) {
                    <p class="coupon-row mb-0">
                      <span class="font-weight-bold">{{coupon.description}}</span>
                      <span class="pull-right">-{{coupon.amount/100 | currency }}</span>
                    </p>
                  }
                </div>
              }
              <div class="pt-4">
                @if (updateSubscriptionService.getCreditTotal()) {
                  <p id="prorated-credit-row" class="left-right-aligner mb-4">
                    <span class="font-weight-bold">Prorated credit added to your account:</span>
                    <span>
                      <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimates[0].credit_note_estimates[0].currency_code}}</span>
                      <span class="purchase-total">{{updateSubscriptionService.getCreditTotal() | currency }}</span>
                    </span>
                  </p>
                }
                @if (updateSubscriptionService.estimates[0].invoice_estimate) {
                  <p id="prorated-amount-row" class="left-right-aligner mb-4">
                    <span class="font-weight-bold">Prorated amount, due now:</span>
                    <span>
                      <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimates[0].invoice_estimate?.currency_code}}</span>
                      <span class="purchase-total">{{updateSubscriptionService.estimates[0].invoice_estimate?.amount_due/100 | currency }}</span>
                    </span>
                  </p>
                }
                @if (updateSubscriptionService.estimates[0].next_invoice_estimate) {
                  <p id="next-invoice-row" class="left-right-aligner mb-0">
                    <span class="font-weight-bold">Next invoice on {{updateSubscriptionService.estimates[0].subscription_estimate?.next_billing_at * 1000 | date:'d-MMM-yyyy'}}:</span>
                    <span>
                      <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimates[0].next_invoice_estimate?.currency_code}}</span>
                      <span class="purchase-total">{{updateSubscriptionService.estimates[0].next_invoice_estimate?.total/100 | currency }}</span>
                    </span>
                  </p>
                }
              </div>
            </div>
          }
        </div>
        <div class="row u_margin-md-top mb-5">
          <div class="col-md-4 mb-2">
            <button id="backButton" type="button" aria-label="Go back" class="btn btn-default btn-hg btn-block" (click)="close()">Back</button>
          </div>
          <div class="col-md-8">
            @if (updateSubscriptionService.getTotalDue() > 0) {
              <button id="payButton" type="submit" class="btn btn-primary btn-hg btn-block" tabindex="1" aria-label="Complete Payment" [disabled]="purchaseLicensesForm.invalid || billingPeriod === 'monthly'">
                <span id="invoiceLabel">Pay {{updateSubscriptionService.getTotalDue() | currency }} Now</span>
              </button>
            }
            @else {
              <button id="updateButton" type="submit" class="btn btn-primary btn-hg btn-block" tabindex="1" aria-label="Update Subscription" [disabled]="purchaseLicensesForm.invalid || billingPeriod === 'monthly'">
                <span id="invoiceLabel">Update Subscription</span>
              </button>
            }
          </div>
        </div>
      </form>
    </div>
  </div>
  <update-subscription-success [purchaseAction]="purchaseAction" [planName]="plan.name" (closeEvent)="close()" [shown]="updateSubscriptionService.purchases[0]?.completed"></update-subscription-success>
  <div class="mt-5 border-top">
    <purchase-footer></purchase-footer>
  </div>
</div>
