import { Component } from '@angular/core';
import { TransitionService, UIRouterGlobals } from '@uirouter/angular';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  constructor(
    private transitionService: TransitionService,
    private uiRouterGlobals: UIRouterGlobals
  ) {
    this.transitionService.onSuccess({}, () => {
      this.updateTitle();
    });

    this.updateTitle();
  }

  private getTitle(): string | undefined {
    const route = this.uiRouterGlobals.current.url;

    switch (route) {
      // Auth routes
      case '/auth':
      case '/auth/unauthorized':
      case '/auth/signin':
        return 'Sign In';

      case '/auth/createaccount':
      case '/auth/joinaccount':
      case '/auth/signup':
      case '/auth/unregistered':
        return 'Sign Up';

      case '/auth/requestpasswordreset':
      case '/auth/resetpassword':
        return 'Reset Password';

      case '/auth/unsubscribe':
        return 'Unsubscribe';

      // Main app routes
      case '/':
      case '/home':
        return 'Home';

      case '/purchase':
      case '/purchase/plans':
      case '/purchase/home':
        return 'Subscribe';

      case '/billing':
      case '/billing/home':
        return 'Billing';

      case '/billing/unpaid':
        return 'Unpaid Invoices';

      case '/billing/invoice':
        return 'Invoice';

      case '/purchase/licenses':
      case '/purchase/licenses/change':
      case '/purchase/licenses/unlimited':
      case '/purchase/licenses/add':
      case '/purchase/licenses/remove':
      case '/purchase/frequency':
      case '/purchase/change-plan':
      case '/purchase/update-plan':
      case '/billing/subscription':
      case '/billing/payment':
        return 'Subscription';

      case '/schedules':
      case '/schedules/list':
        return 'Schedules';

      case '/schedules/details':
        return 'Edit Schedule';

      case '/schedules/add':
        return 'Add Schedule';

      case '/displays':
      case '/displays/list':
        return 'Displays';

      case '/displays/change':
      case '/displays/details':
        return 'Display Settings';

      case '/displays/alerts':
        return 'CAP Alerts';

      case '/editor':
      case '/editor/home':
      case '/editor/list':
      case '/editor/add':
        return 'Presentations';

      case '/editor/workspace':
      case '/editor/workspace/artboard':
      case '/editor/workspace/htmleditor':
      case '/editor/templates':
      case '/editor/templates/edit':
        return 'Edit Presentation';

      case '/storage':
      case '/storage/home':
        return 'Storage';

      case '/company':
      case '/company/add':
      case '/company/details':
        return 'Company';

      case '/company/licenses':
        return 'Companies and Licenses';

      case '/user':
      case '/user/add':
      case '/user/add2':
      case '/user/list':
      case '/user/details':
        return 'Users';

      default:
        return undefined;
    }
  }

  private updateTitle(): void {
    const title = this.getTitle();
    const titleText = title ? `${title} | ` : '';

    document.title = titleText + 'Rise Vision Apps';
  }
}
