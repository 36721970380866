import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, DestroyRef, inject, ViewChild, AfterViewInit, HostListener, OnDestroy } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounceTime, Subject, Subscription, tap } from "rxjs";

@Component({
  template: ''
})
export abstract class PageWithListComponent<T> implements AfterViewInit, OnDestroy {
  private scrollSubscription: Subscription;
  private resizeObserver: ResizeObserver;
  private _scrollViewport: CdkVirtualScrollViewport;
  protected destroyRef = inject(DestroyRef);

  /**
   * @param viewport - The scrolling viewport
   * Make sure that the <cdk-virtual-scroll-viewport> element has the
   * #scrollViewport template reference variable
   */
  @ViewChild('scrollViewport')
  set scrollViewport(viewport: CdkVirtualScrollViewport) {
    this._scrollViewport = viewport;
    if (!viewport) {
      this.scrollSubscription?.unsubscribe();
      return;
    }
    this.scrollSubscription = this.registerScrollView<T>(
      viewport,
      this.getScrollingList()
    ).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
  get scrollViewport(): CdkVirtualScrollViewport {
    return this._scrollViewport;
  }

  private onScrollSubject = new Subject<void>();

  @HostListener('window:resize')
  protected onResize(): void {
    this.updateListHeight();
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.onResize();
    });

    const content = document.querySelector('#content-layout-body');
    const weekly = document.querySelector('#TemplateList1');
    if (content) {
      this.resizeObserver.observe(content);
    }
    if (weekly) {
      this.resizeObserver.observe(weekly);
    }
    setTimeout(() => {
      this.onResize();
      this.scrollViewport?.checkViewportSize();
    });

    this.onScroll.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(500)
    ).subscribe(() => {
      this.refresh();
    });
  }

  protected updateListHeight(): void {
    if (!this.scrollViewport?.elementRef?.nativeElement) {
      return;
    }

    const viewportHeight = window.innerHeight;
    const element = this.scrollViewport.elementRef.nativeElement;
    const scrollViewportTop = element.getBoundingClientRect().top;
    const bottomMargin = 20;
    const availableHeight = viewportHeight - scrollViewportTop - bottomMargin;
    const minHeight = 200;
    const finalHeight = Math.max(availableHeight, minHeight);

    element.style.height = `${finalHeight}px`;
  }

  private registerScrollView<T>(
    scrollElement: CdkVirtualScrollViewport,
    scrollingList: ScrollingList<T>
  ) {
    return scrollElement.scrolledIndexChange.pipe(
      debounceTime(100),
      tap(() => this.onScrollSubject.next()),
      tap(index => (
        index !== 0
        && index >= scrollElement.getDataLength() * 0.7
        && !scrollingList.loadingItems
      ) && scrollingList.load()
    ));
  }

  abstract getScrollingList(): ScrollingList<T>;
  abstract refresh(): void;

  protected get onScroll() {
    return this.onScrollSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}


export interface ScrollingList<T> {
  load: () => void;
  doSearch: () => void;
  sortBy: (sortBy: string, reverse?: boolean) => void;
  select: (item: T) => void;
  selectAll: () => void;
  loadingItems: boolean;
  apiError: string;
  errorMessage: string;
  items: {
    list: T[];
  }
}
